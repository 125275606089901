import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSectionWrapper from "../../Components/ProForms/FormSectionWrapper";
import SettingFormsLayout from "../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../utils/constant";
import ProSelectField from "../../Components/Inputs/SelectField";
import Layout from "../../Components/Layout";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import { errorToast, successToast } from "../../../utils/toastHelper";
import {
  GET_ALL_CLIENT_DROPDOWN,
  GET_ALL_CLIENT_PROJECTS,
  GET_ALL_PROJECT_MENTORS,
  INITIATE_APPRENTICE_MENTOR_MAPPING
} from "../../../utils/services/apiPath";
import { postApi, getApi } from "../../../utils/services";
import ProGrid from "../../Components/ProGrid/v2";
import useColumn from "../../../utils/customHooks/useColumns";
import Loading from "../../Components/Loading";

const MentorApprenticePairingFormConfig = {
  client: {
    label: "Client",
    perKey: "Client",
    rules: { required: "Select Client!" }
  },
  communicationType: {
    label: "Communication Method",
    perKey: "method",
    rules: { required: "Communication Method is Required!" }
  },
  project: {
    label: "Project",
    perKey: "project",
    rules: { required: "Project is Required!" }
  },
  messageType: {
    label: "Message Type",
    perKey: "message",
    rules: { required: "Message is Required!" }
  }
};
const typeOptions = [
  { label: "SMS", value: "1" },
  { label: "Email", value: "2" },
  { label: "Both", value: "3" }
];

const messageOptions = [
  { label: "Mentor Apprentice Pairing", value: "MAPairing" },
  { label: "WPS Time Allocation", value: "WPS" }
];

export default function MentorApprenticePairing() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [isLoading] = useState(false);
  const [partialList, setPartialList] = useState([]);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [clients, setClients] = useState([]);
  const [clientProjects, setClientProjects] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(MentorApprenticePairingFormConfig));
  const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(MentorApprenticePairingFormConfig) });

  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const backAction = () => navigate("/communication");
  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => (isAddMode ? backAction() : setFormConfig(changeMode("edit")));
  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const clientGuid = watch("client");
  const projectGuid = watch("project");

  const resetForm = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    setMentors([]);
  };

  const onSubmit = async data => {
    setStatus(STAUTES.LOADING);
    const payload = {
      clientGuid: data.client,
      projectGuid: data.project,
      employeeTermTypeguids: partialList,
      communicationType: data.communicationType,
      messageType: data.messageType
    };
    const { error } = await postApi(INITIATE_APPRENTICE_MENTOR_MAPPING, payload);
    if (error) return errorToast(error);
    resetForm();
    successToast(`Request apprentice link has been sent to mentors successfully.`);
    setStatus(STAUTES.IDLE);
  };

  const fetchAllClients = async () => {
    const data = await getApi(GET_ALL_CLIENT_DROPDOWN);
    setClients(data);
  };

  const fetchAllClientProjects = async clientGuid => {
    const data = await getApi(`${GET_ALL_CLIENT_PROJECTS}/${clientGuid}`);
    setClientProjects(Array.isArray(data) ? data?.map(item => ({ label: item.projectName, value: item.projectGuid })) : []);
  };

  const fetchAllMentors = async projectGuid => {
    setStatus(STAUTES.LOADING);
    const data = await getApi(`${GET_ALL_PROJECT_MENTORS}/${projectGuid}`);
    setMentors(data || []);
    setStatus(STAUTES.IDLE);
  };

  const handleSelectionChange = selection => {
    setPartialList(selection);
  };

  useEffect(() => {
    fetchAllClients();
    if (!isAddMode) onEdit();
  }, [id]);

  useEffect(() => {
    if (clientGuid) fetchAllClientProjects(clientGuid);
  }, [clientGuid]);

  useEffect(() => {
    if (projectGuid) fetchAllMentors(projectGuid);
  }, [projectGuid]);

  const columns = useColumn(
    [
      {
        field: "firstName",
        headerName: "First Name"
      },
      {
        field: "lastName",
        headerName: "Last Name"
      },
      {
        field: "email",
        headerName: "Email"
      },
      {
        field: "phoneNumber",
        headerName: "Phone"
      }
    ],
    true
  );

  const defaultFormProps = { control, size: 3, formValues: {}, isAddMode, handleReadValueclick };

  return (
    <Layout>
      <SettingFormsLayout
        status={status}
        hideBackbutton={true}
        title={"Mentor Apprentice Messaging"}
        beingEdited={isEditModeOn}
        isAddMode={true}
        hideEdit={true}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        buttonLabel={"Send"}
      >
        {!isLoading ? (
          <FormSectionWrapper title={"Enter Details"}>
            <ProSelectField {...defaultFormProps} {...formConfig.client} options={clients} />
            <ProSelectField {...defaultFormProps} {...formConfig.project} options={clientProjects} />
            <ProSelectField {...defaultFormProps} {...formConfig.communicationType} options={typeOptions} />
            <ProSelectField {...defaultFormProps} {...formConfig.messageType} options={messageOptions} />

            <ProGrid
              title="Mentors"
              loading={status === STAUTES.LOADING}
              options={{
                getRowId: row => row.employeeTermTypeGuid,
                pagination: false,
                style: { maxHeight: "500px" }
              }}
              columns={columns}
              hideAddButton
              hideExport
              rows={mentors}
              checkboxSelection
              onRowSelectionModelChange={handleSelectionChange}
            />
          </FormSectionWrapper>
        ) : (
          <Loading></Loading>
        )}
      </SettingFormsLayout>
    </Layout>
  );
}
